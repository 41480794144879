
import { defineComponent, PropType } from 'vue'
export default defineComponent({
  props: {
    items: {
      type: Array as PropType<{}[]>,
      required: true
    }
  },
  setup: () => {
    return {

    }
  }
})
