
import DescriptionList from './DescriptionList.vue'
export default {
  components: { DescriptionList },
  setup: () => {
    return {

    }
  }
}
